import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { EXHIBITOR_BASE_FRAGMENT } from '@/graphql/_Fragments/Exhibitor/Base';

export const DEAL_CARD_FRAGMENT = `
  fragment dealCardFragment on Deal {
    id
    uid
    schemaCode
    name
    title
    featured
    startTime
    endTime
    displayFileResource {
      ...fileResourceBaseFragment
    }
    exhibitor {
      ...exhibitorBaseFragment
    }
    _isBookmarked(myUid: "%authUser%")
    _isRecommendedForMe
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
  ${EXHIBITOR_BASE_FRAGMENT}
`;
